import { useState, type ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { useToast, FloatingLabelInput, PhoneInput } from "@aviary";
import { getPhoneWithoutCountryCode } from "@aviary/components/PhoneInput";
import { isValidPhoneNumber } from "@aviary/components/PhoneInput/validatePhoneNumber";
import { ChooseAuthenticationMethod } from "@shared/MultifactorAuthentication/components/ChooseAuthenticationMethod/ChooseAuthenticationMethod";
import {
  useEnableMfaSharedMutation,
  type EnableMfaSharedMutation,
} from "@shared/MultifactorAuthentication/data/EnableMFA.mutation.generated";
import {
  useSetupMfaSharedMutation,
  type SetupMfaSharedMutation,
} from "@shared/MultifactorAuthentication/data/SetupMFA.mutation.generated";
import { DisplayBackupCode } from "@shared/MultifactorAuthentication/steps/DisplayBackupCode/DisplayBackupCode";
import { DownloadAuthenticator } from "@shared/MultifactorAuthentication/steps/DownloadAuthenticator/DownloadAuthenticator";
import { EnableWithAuthenticator } from "@shared/MultifactorAuthentication/steps/EnableWithAuthenticator/EnableWithAuthenticator";
import { EnableWithSMS } from "@shared/MultifactorAuthentication/steps/EnableWithSMS/EnableWithSMS";
import { EnterPhoneNumber } from "@shared/MultifactorAuthentication/steps/EnterPhoneNumber/EnterPhoneNumber";
import {
  type AuthFlow,
  smsAuthFlow,
  type MFAStepDetails,
  authenticatorAuthFlow,
  type CurrentMFAProgress,
} from "@shared/MultifactorAuthentication/steps/MFASteps";
import { SecureYourAccount } from "@shared/MultifactorAuthentication/steps/SecureYourAccount/SecureYourAccount";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import { TwoFactorAuthenticationMethods as AuthMethod } from "@shared/types/graphqlGenerated";

interface HistoryState {
  mfaToken?: string;
}

interface Props {
  existingAuthFlow?: AuthFlow;
  renderNavigation: (currentProgress: CurrentMFAProgress) => ReactElement;
  mfaToken?: string;
  verificationToken?: string;
}

const SetupMFAFlow = ({
  existingAuthFlow,
  renderNavigation,
  mfaToken,
  verificationToken,
}: Props) => {
  const { makeToast } = useToast();
  const { t } = useTranslation();
  const { country } = useSharedGlobalConfig();
  const preferredCountry = country === "United States" ? "US" : "CA";
  const { state }: { state: HistoryState } = useLocation();
  const token = state?.mfaToken || mfaToken;

  const [currentAuthFlow, setCurrentAuthFlow] = useState<AuthFlow>(existingAuthFlow ?? smsAuthFlow);
  const [currentStep, setCurrentStep] = useState<MFAStepDetails>(
    existingAuthFlow?.steps[0] ?? smsAuthFlow.steps[0]
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [oneTimePasscode, setOneTimePasscode] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [manualSetupCode, setManualSetupCode] = useState("");
  const [backupCode, setBackupCode] = useState("");
  const [redirectPath, setRedirectPath] = useState("");

  const goToNextStep = () => {
    const nextStep = currentAuthFlow.steps.find(s => s.step === currentStep.nextStep);
    setCurrentStep(nextStep || currentStep);
  };

  const [setupMFA, { loading: loadingSetupMFA }] = useSetupMfaSharedMutation({
    onCompleted: data => handleSetupMFAComplete(data),
    onError: ({ message }) => makeToast("error", message),
  });

  const [enableMFA, { loading: loadingEnableMFA }] = useEnableMfaSharedMutation({
    onCompleted: data => handleEnableMFAComplete(data),
    onError: ({ message }) => makeToast("error", message),
    refetchQueries: ["MFA_Shared_Query"],
  });

  const handleSetupMFAComplete = (data: SetupMfaSharedMutation) => {
    const {
      qrCodeUrl: url,
      manualSetupCode: manualCode,
      errors,
    } = data?.auth?.userSetupTwoFactorAuthentication || {};

    if (errors?.message) {
      makeToast("error", t(l.mfa.errorOccurredTryAgain));
    } else {
      setQrCodeUrl(url);
      setManualSetupCode(manualCode);
      goToNextStep();
    }
  };

  const handleEnableMFAComplete = (data: EnableMfaSharedMutation) => {
    const {
      newBackupCode,
      twoFactorAuthentication,
      errors,
      redirectPath: path,
    } = data?.auth?.userEnableTwoFactorAuthentication || {};

    if (errors?.message || !twoFactorAuthentication?.isEnabled) {
      makeToast("error", t(l.mfa.codeDoesNotMatch));
    } else {
      setBackupCode(newBackupCode);
      setRedirectPath(path);
      makeToast("success", t(l.mfa.enableSuccess));
      goToNextStep();
    }
  };

  const shouldNextBeDisabled = () => {
    if (currentStep.step === "enterMobilePhone") {
      return !isValidPhoneNumber(getPhoneWithoutCountryCode(phoneNumber));
    }
    if (currentStep.step === "enableWithSMS" || currentStep.step === "enableWithAuthenticator") {
      return !oneTimePasscode;
    }

    return false;
  };

  const isNextDisabled = shouldNextBeDisabled();
  const isLoading = loadingSetupMFA || loadingEnableMFA;

  const fireActionForStep = () => {
    if (currentStep.action === "setupMFA") {
      // app flow doesn't use otpMethodField
      const includeMethodField = currentAuthFlow.authMethod === AuthMethod.Sms;
      setupMFA({
        variables: {
          input: {
            otpMethod: currentAuthFlow.authMethod,
            otpMethodField: includeMethodField ? phoneNumber : null,
            token,
            ...(verificationToken && { verificationToken }),
          },
        },
      });
    } else if (currentStep.action === "enableMFA") {
      enableMFA({
        variables: {
          input: {
            otp: oneTimePasscode,
            token,
            ...(verificationToken && { verificationToken }),
          },
        },
      });
    } else {
      goToNextStep();
    }
  };

  const getPhoneLast4 = () => {
    const phone = getPhoneWithoutCountryCode(phoneNumber);
    if (!phone || phone.length < 5) {
      return phone;
    }

    return phone.slice(-4);
  };

  const handleAuthMethodSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === AuthMethod.Sms) {
      setCurrentAuthFlow(smsAuthFlow);
      setCurrentStep(smsAuthFlow.steps[0]);
    } else if (event.target.value === AuthMethod.App) {
      setCurrentAuthFlow(authenticatorAuthFlow);
      setCurrentStep(authenticatorAuthFlow.steps[0]);
    }
  };

  const handleStepChange = (isPrevious?: boolean) => {
    if (isPrevious) {
      const prevStep = currentAuthFlow.steps.find(s => s.step === currentStep.previousStep);
      setCurrentStep(prevStep || currentStep);

      return;
    }

    fireActionForStep();
  };

  const isChangeFlow = !!verificationToken;

  const skippable = () => {
    // if verificationToken exists, they're using the change flow
    if (verificationToken) {
      return false;
    } else if (!mfaToken) {
      return true;
    } else return false;
  };

  const renderCurrentStep = () => {
    switch (currentStep.step) {
      case "chooseAuthenticationMethod":
        return (
          <SecureYourAccount isSkippable={skippable()} isChangeFlow={isChangeFlow}>
            <ChooseAuthenticationMethod
              selectedAuthMethod={currentAuthFlow.authMethod}
              onSelect={handleAuthMethodSelect}
            />
          </SecureYourAccount>
        );
      case "downloadAuthenticator":
        return <DownloadAuthenticator isChangeFlow={isChangeFlow} />;
      case "enterMobilePhone":
        return (
          <EnterPhoneNumber isChangeFlow={isChangeFlow}>
            <PhoneInput
              id={t(l.mfa.enterMobilePhone.number)}
              name={t(l.mfa.enterMobilePhone.number)}
              label={t(l.mfa.enterMobilePhone.number)}
              aria-label={t(l.mfa.enterMobilePhone.number)}
              value={phoneNumber}
              onChange={phoneNum => setPhoneNumber(phoneNum)}
              preferredCountryCode={preferredCountry}
              errors={null}
              required
            />
          </EnterPhoneNumber>
        );
      case "enableWithSMS":
        return (
          <EnableWithSMS phoneLast4={getPhoneLast4()} isChangeFlow={isChangeFlow}>
            <FloatingLabelInput
              id="oneTimePasscodeSMS"
              type="text"
              value={oneTimePasscode}
              label={t(l.mfa.oneTimeCode)}
              handleChange={e => setOneTimePasscode(e.target.value)}
              errors={null}
              required
            />
          </EnableWithSMS>
        );
      case "enableWithAuthenticator":
        return (
          <EnableWithAuthenticator
            qrCodeUrl={qrCodeUrl}
            manualSetupCode={manualSetupCode}
            isChangeFlow={isChangeFlow}
          >
            <FloatingLabelInput
              id="oneTimePasscodeAuthenticator"
              type="text"
              value={oneTimePasscode}
              label={t(l.mfa.oneTimeCode)}
              handleChange={e => setOneTimePasscode(e.target.value)}
              errors={null}
              required
            />
          </EnableWithAuthenticator>
        );
      case "displayBackupCode":
        return <DisplayBackupCode backupCode={backupCode} isChangeFlow={isChangeFlow} />;
    }
  };

  return (
    <>
      {renderCurrentStep()}
      {renderNavigation({
        currentStep,
        handleStepChange,
        isNextDisabled,
        isLoading,
        mfaToken,
        redirectPath,
      })}
    </>
  );
};

export { SetupMFAFlow };
