export const signin = {
  SignInToYourAccount: "Sign in to your account",
  DoYouNormallySignInWithGoogle: "Do you normally sign in with Google?",
  LearnHowToSignInWithoutGoogle: "Learn how to sign in without Google.",
  PasswordIsRequired: "Password is required",
  IncorrectEmailOrPassword: "Incorrect email or password. Please try again.",
  Continue: "Continue",
  EmailRequired: "Email is required",
  EmailMustHaveAt: `Email must include "@"`,
  Email: "Email",
  HowToSignInWithoutGoogle: "How to sign into Fullscript without Google",
  SuccessfullyConnected: "You have successfully connected!",
  YoureNowReady: "You are now ready to start using Fullscript directly in {{ displayName }}.",
  ConnectToPatients: "Connect your {{ patientTerm }}.",
  CreateTreatmentPlan: "Create a supplement {{ treatmentPlanTerm }}.",
  WatchYourPatients: "Watch your {{ patientTerm }} get better — and your practice grow.",
  CloseWindowAndStartAgain: "Close this window and start again.",
  CloseWindow: "Close this window.",
  GetStarted: "Get started",
  YourPracticeNeedsAC: "Your practice needs to sign an A&C",
  NeedsSignedAC:
    "In order to begin building supplement plans, your practice needs to complete an Authorization & Consent agreement with Fullscript. Contact <0>{{supportEmail}}</0> to get an A&C in place and gain full access to the integration.",
  YourPracticeNeedsBAA: "Your practice needs a BAA.",
  NeedsSignedBAA:
    "In order to begin building supplement plans, your practice needs to complete a BAA (Business Associate Agreement) with Fullscript. Contact <0>{{supportEmail}}</0> to get a BAA in place and gain full access to the integration.",
  PracticeIdClaimed: "Your Fullscript Store is linked to a different practice.",
  AccountAlreadyLinked: "Your Fullscript user is linked to a different {{displayName}} provider.",
  AssociatedWithADifferentFsStore:
    "Each Fullscript Store can only be linked to a single {{displayName}} practice.  Talk to your Fullscript Store Owner, or contact  <0>{{supportEmail}}</0> for help.",
  LinkedToDifferentProvider:
    "Each Fullscript user account can only be linked to a single {{displayName}} provider account. Try logging in with a different user, or un-link your account from {{displayName}} in Account Settings in the  <0>{{webApp}}</0>",
  GoBackToSignIn: "Go back to sign in",
  SomethingWentWrong: "Something went wrong...",
  GoogleIsNotSupported: `“Sign In with Google” is not supported from within {{displayName}} yet. If you normally sign
      in using Google, you can add a password to your Fullscript account in order to be able to
      sign in through {{displayName}}.`,
  AddFullscriptPassword: "Add a Fullscript password",
  LogInWithGoogle: "Log into the Fullscript main web app using Sign In with Google",
  ClickOnUserIcon: "Click on your user avatar menu in the top right corner",
  ChooseAccountSettings: "Choose “Account Settings”",
  ScrollToLogin: "Scroll to “Login Settings” and add a password",
  UseThisPassword: "Use this password to log into Fullscript from {{displayName}}.",
  ReviewBAA: "Review and sign the terms of service for this Fullscript integration",
  SignExternalBAA:
    "To proceed, please complete the Business Associate Agreement via DocuSign. Once signed, return to this window to continue.",
  AllowPopups:
    "You may need to enable pop-ups in your browser. Click the button below to open the document, review, and complete the signing process.",
  OpenDocuSign: "Open DocuSign",
  BaaPollingInProgress:
    "We're confirming your DocuSign signature. This may take a few minutes. Please keep this tab open.",
  BaaStoppedPolling: "Unable to confirm signature",
  BaaPollLimitExceeded:
    "We couldn’t confirm your DocuSign signature. If you’ve finished signing the document, please confirm using the button below.",
  BaaConfirmSignature: "I have signed the BAA",
};
