import { Trans, useTranslation } from "react-i18next";

import { Button, Link, Typography } from "@aviary";
import { BackupCode } from "@shared/components/BackupCode/BackupCode";
import { getPlatformName } from "@shared/helpers/getPlatformName";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";

import * as styles from "./NewBackupCodeView.styles";

const twoFactorSettingsRoutes = {
  patient: "/u/account",
  practitioner: {
    fullscript: "/o/account_settings/security",
    emerson: "/emerson/account/security",
  },
  clerk: "/o/account_settings",
} as const;

interface Props {
  newBackupCode: string;
  redirectPath: string;
  roleType: string;
}

const NewBackupCodeView = ({ newBackupCode, redirectPath, roleType }: Props) => {
  const { t } = useTranslation();
  const { storePlatform, isEmerson } = useSharedGlobalConfig();

  const navigateToRedirectPath = () => {
    window.location.assign(redirectPath);
  };

  const getAccountSettingsRoute = () => {
    switch (roleType) {
      case "Clerk":
        return twoFactorSettingsRoutes.clerk;
      case "Practitioner":
        return isEmerson
          ? twoFactorSettingsRoutes.practitioner.emerson
          : twoFactorSettingsRoutes.practitioner.fullscript;
      case "Patient":
        return twoFactorSettingsRoutes.patient;
      default:
        return null;
    }
  };

  const renderAccountSettingsLink = () => {
    const accountSettingsRoute = getAccountSettingsRoute();

    if (accountSettingsRoute) {
      return <Link href={accountSettingsRoute}>Account settings</Link>;
    }

    return <strong>Account settings</strong>;
  };

  return (
    <div css={styles.wrapper}>
      <Typography type="h1" sizeOverride="h3">
        {t(l.mfa.backupCode)}
      </Typography>
      <Trans
        i18nKey={l.mfa.newBackupCodeView.subtitle}
        values={{ storePlatform: t(getPlatformName(storePlatform)) }}
      >
        <Typography isMarginless>
          Here is your new backup code. Please store it securely.
        </Typography>
        <Typography>
          You can view this again in your {storePlatform}
          {renderAccountSettingsLink()}.
        </Typography>
      </Trans>
      <BackupCode backupCode={newBackupCode} />
      <Button css={styles.nextButton} onClick={navigateToRedirectPath}>
        {t(l.sharedCommon.Next)}
      </Button>
    </div>
  );
};

export { NewBackupCodeView, twoFactorSettingsRoutes };
