/* eslint-disable @typescript-eslint/naming-convention */
import { clinicRoutes } from "@clinic/shared/data/clinicRoutes";
import type { ProductSwapPlanType } from "@clinic/shared/types/PlanType";
import type { ProductSwapAvailabilityStatus } from "@shared/types/availabilityStatus";

const practitionerRoutes = {
  ...clinicRoutes,
  legacyHome: "/p/stores",
  account: "/p/stores/account",
  peerReferrals: "/o/peer_referrals",
  legacyDashboard: "/p/stores/dashboard",
  dashboard: "/o/dashboard",
  referral: "/p/stores/referral",
  store_welcome: "/p/welcome",
  wrong_account_type: "/p/wrong-account-type",
  stock_locations: "/w/stock_locations",
  npSync: "/w/np-sync",
  onboardingCredentialsUpload: "/o/onboarding/credentials",
  onboardingNPIConfirmation: "/o/onboarding/npi-confirmation",
  productSwaps: {
    root: "/o/product-swaps",
    listPage: "/o/product-swaps/:planType",
    getListPage: (planType: ProductSwapPlanType) => `/o/product-swaps/${planType}`,
    detailPage: "/o/product-swaps/:planType/:productId/:variantId",
    getDetailPage: (planType: ProductSwapPlanType, productId: string, variantId: string) =>
      `/o/product-swaps/${planType}/${productId}/${variantId}`,
  },
  productSwapsV2: {
    root: "/o/product-swaps",
    listPage: "/o/product-swaps/:status",
    getListPage: (status: ProductSwapAvailabilityStatus) => `/o/product-swaps/${status}`,
    detailPage: "/o/product-swaps/:status/:productId/:variantId",
    getDetailPage: (status: ProductSwapAvailabilityStatus, productId: string, variantId: string) =>
      `/o/product-swaps/${status}/${productId}/${variantId}`,
    howItWorksVideoLink: "https://www.youtube.com/embed/4XWqc0-meCo",
  },
  landingPagePreview: urlSlug => `/welcome/${urlSlug}?preview=true`,
  switch_accounts: "/accounts",
} as const;

export { practitionerRoutes };
