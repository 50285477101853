// @typescript-eslint/ban-ts-comment - required because children in react-router-dom are not compatible with objects in Trans components
/* eslint-disable @typescript-eslint/ban-ts-comment, no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import type { ReactNode } from "react";
import * as router from "react-router-dom";

interface Props
  extends Omit<router.NavLinkProps, "children">,
    React.RefAttributes<HTMLAnchorElement> {
  children?:
    | ReactNode
    | ((props: router.NavLinkRenderProps) => React.ReactNode)
    | Record<string, unknown>;
}

/**
 * A NavLink component wrapping NavLink from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 */
const NavLink = (props: Props) => {
  let isRouter = true;

  try {
    router.useLocation();
  } catch (e) {
    isRouter = false;
  }

  if (isRouter) {
    // @ts-expect-error
    return <router.NavLink {...props} />;
  }

  // @ts-expect-error
  return <remix.NavLink {...props} />;
};

export { NavLink, type Props as NavLinkProps };
