import type { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link, Spacer, Typography } from "@aviary";
import { sharedRoutes } from "@shared/data/sharedRoutes";
import { l } from "@shared/locales/i18n";

import * as styles from "./EnterPhoneNumber.styles";

interface Props {
  children: ReactNode;
  isChangeFlow?: boolean;
}

const EnterPhoneNumber = ({ children, isChangeFlow }: Props) => {
  const { t } = useTranslation();

  const renderContent = () => {
    if (isChangeFlow) {
      return (
        <>
          <Typography css={styles.title} type="h4">
            {t(l.mfa.enterMobilePhone.title)}
          </Typography>
          <Typography>
            {t(l.mfa.enterMobilePhone.subtitle)}{" "}
            <Link
              isColor="system"
              href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-multi-factor-verification/#enabling-multi-factor-authentication-sms-option"
            >
              {t(l.sharedCommon.learnMore)}
            </Link>
          </Typography>
          <Spacer height="one" />
        </>
      );
    }

    return (
      <div css={styles.header}>
        <Typography css={styles.title} type="h1">
          {t(l.mfa.enterMobilePhone.title)}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {renderContent()}
      <Typography isSecondaryWeight isMarginless>
        {t(l.mfa.step1)}
      </Typography>
      <Typography>{t(l.mfa.enterMobilePhone.enterYourNumber)}</Typography>
      <Spacer height="half" />
      {children}
      <Spacer height="oneHalf" />
      <Typography type="caption" data-testid="mobile-phone-disclaimer">
        <Trans i18nKey={l.mfa.enterMobilePhone.disclaimer}>
          Using your mobile number for security means you accept our
          <Link href={sharedRoutes.external.privacyPolicy}>privacy policy</Link> and
          <Link href={sharedRoutes.external.termsOfService}>Terms of Service</Link>. Standard text
          message rates may apply.
        </Trans>
      </Typography>
    </>
  );
};

export { EnterPhoneNumber };
