import { useTheme } from "@emotion/react";
import { colorProfileMapper } from "aviary-tokens";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";

import type { BoxProps } from "./Box.types";
import { DeprecatedBox } from "./DeprecatedBox";

import * as styles from "./Box.styles";

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/components/layout/Box
 *
 * @deprecated Use `Box` from `@fullscript/aviary-web` instead.
 */
const Box = ({
  isColor = null,
  isGutterless = false,
  padding = "normal",
  isBordered = false,
  ...props
}: BoxProps) => {
  const isDS4Enabled = useIsDS4Enabled();
  const currentTheme = useTheme();
  const themeColors = colorProfileMapper(currentTheme);

  if (!isDS4Enabled)
    return <DeprecatedBox {...{ isColor, isGutterless, padding, isBordered, ...props }} />;

  const { children, layoutStyles, accentColor, ...rest } = props;

  const boxStyles = [
    styles.box,
    styles.padding[padding],
    isGutterless && styles.gutterless,
    isColor && styles.themedColorStyles(themeColors[isColor]),
    accentColor && styles.accentColor[accentColor],
  ];

  return (
    <section css={[boxStyles, layoutStyles]} {...rest}>
      {children}
    </section>
  );
};

export { Box };
