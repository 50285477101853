import { useTranslation } from "react-i18next";

import { Column, Columns, Separator, Typography } from "@aviary";
import PhoneScreenSVGComponent from "@shared/assets/images/PhoneScreen";
import { getPlatformName } from "@shared/helpers/getPlatformName";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { l } from "@shared/locales/i18n";

import * as styles from "./DownloadAuthenticator.styles";

interface Props {
  isChangeFlow?: boolean;
}

const DownloadAuthenticator = ({ isChangeFlow }: Props) => {
  const { t } = useTranslation();
  const { storePlatform } = useSharedGlobalConfig();

  const renderTitle = () => {
    if (isChangeFlow) {
      return <Typography type="h4">{t(l.mfa.downloadAuthenticator.title)}</Typography>;
    }

    return (
      <div css={styles.header}>
        <Typography type="h1">{t(l.mfa.downloadAuthenticator.title)}</Typography>
      </div>
    );
  };

  return (
    <>
      {renderTitle()}
      <Columns preserveMobileColumns>
        <Column columnWidth={2}>
          <PhoneScreenSVGComponent css={styles.svgImage} />
        </Column>
        <Column columnWidth={10} preserveMobileColumns>
          <Typography isSecondaryWeight isMarginless>
            {t(l.mfa.step1)}
          </Typography>
          <Typography isMarginless>
            {t(l.mfa.downloadAuthenticator.downloadAnAuthenticatorApp)}
          </Typography>
          <Typography>
            <ul css={styles.authenticatorList}>
              <li>{t(l.mfa.downloadAuthenticator.googleAuthenticator)}</li>
              <li>{t(l.mfa.downloadAuthenticator.microsoftAuthenticator)}</li>
              <li>{t(l.mfa.downloadAuthenticator.lastPassAuthenticator)}</li>
              <li>{t(l.mfa.downloadAuthenticator.twilioAuthyAuthenticator)}</li>
            </ul>
          </Typography>
        </Column>
      </Columns>
      <Separator css={styles.separator} />
      <Columns preserveMobileColumns>
        <Column columnWidth={2}>
          <PhoneScreenSVGComponent css={styles.svgImage} />
        </Column>
        <Column columnWidth={10} preserveMobileColumns>
          <Typography isSecondaryWeight isMarginless>
            {t(l.mfa.step2)}
          </Typography>
          <Typography>
            {t(l.mfa.downloadAuthenticator.openTheApp, {
              platform: t(getPlatformName(storePlatform)),
            })}
          </Typography>
        </Column>
      </Columns>
    </>
  );
};

export { DownloadAuthenticator };
