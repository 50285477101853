export const auth = {
  authenticatorAppInstructions: {
    step1: "Download an authenticator app from the the app store on your mobile device or tablet.",
    step2:
      'Open the app, follow the installation process, and click "Next" to receive your {{platform}} QR code.',
  },
  authenticatorApp: "Authenticator app",
  authenticationMethod: "Authentication method",
  authenticationMethodSubtitle:
    "Secure your {{platform}} account by using a one-time code in addition to your password when signing in.",
  backupCode: "Backup code",
  backupCodeStoreSafely:
    "An alternative method you can use when signing in. It's important to keep your backup code secure by storing it in a safe place.",
  backupCodeCopied: "Backup code successfully copied",
  backupCodeModal: {
    description:
      "This code can only be used once as an alternative sign-in method if you lose access to your authenticator app, email, or text messages. It’s important to store your backup code securely.",
    generateNewCode: "Generate a new code",
    newCodeGenerated: {
      clerk:
        "Here is your new backup code. Please store it securely. You can access it again in your {{platform}} settings under Account settings > Basic info.",
      patient:
        "Here is your new backup code. Please store it securely. You can access it again in your {{platform}} settings under My account.",
      practitioner:
        "Here is your new backup code. Please store it securely. You can access it again in your {{platform}} settings under Account settings > Security.",
    },
  },
  backupCodeDescription:
    "Backup codes can only be used once and are essential for accessing your account when you can't do so normally. We recommend securely storing them by taking a screenshot or writing them down in a safe place.",
  codeDoesNotMatch: "The code does not match",
  codeSentToEmail: "A one-time code has been sent to your email <strong>{{email}}</strong>.",
  codeSentToMobileNumber:
    "A one-time code has been sent to your mobile number ending in <strong>{{phoneLast4}}</strong>.",
  codeWillExpireIn10: "This code will expire in 10 minutes.",
  copyBackupCode: "Copy backup code",
  copyCode: "Copy code",
  didntGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  didntGetTextMessage: "Didn't get the text message? <1>Resend</1>.",
  disable: "Disable",

  downloadAnAuthenticatorApp: "Download an authenticator app",
  emailHasBeenSent: "An email has been sent to {{ email }}",
  emailNotSent: "An error occurred while sending your email. Please try again.",
  textMessageHasBeenSent:
    "A text message has been sent to the mobile number ending in {{ phoneLast4 }}",
  enterCodeFromAuthenticator: "Enter the one-time code you see in your authenticator app.",
  enterCodeFromEmail: "Enter the one-time code from the email sent to you by {{platform}}.",
  enterCodeFromSms: "Enter the one-time code from the text message sent to you by Fullscript.",
  errorOccurredTryAgain: "An error has occurred. Please try again.",
  generateCodeViaAuthenticatorApp: "Generate a one-time code using an authenticator app",
  important: "Important",
  manualSetupCodeCopied: "Manual setup code successfully copied",
  mobilePhoneForm: {
    cta: "Send code",
    disclaimer:
      "By providing a phone number you consent to and authorize our use of this phone number. See our <1>Terms of Service</1> and <3>Privacy Policy</3> for more details. Standard message and data rates may apply.",
    emersonDisclaimer:
      "By providing a phone number you consent to and authorize our use of this phone number. See our <1>Terms of Sale</1> and <3>Privacy Policy</3> for more details. Standard message and data rates may apply.",
    enterYourNumber: "Enter your mobile number to receive a one-time code.",
    invalidNumberError: "Please provide a valid mobile number.",
    tooLongError: "Phone number must have at most 10 digits",
    number: "Mobile number",
    subtitle: "To sign in, use a one-time code from your mobile number along with your password.",
  },
  oneTimeCode: "One time code",
  receiveOneTimeCodeViaEmail: "Receive a one-time code through your email",
  receiveOneTimeCodeViaTextMessage: "Receive a one-time code through your mobile phone",
  secureAccountWithOTCAndPassword:
    "Secure your account by using a one-time code in addition to your password when signing in.",
  scanTheQrCode: "Scan the QR code or manually enter the code in your authenticator app.",
  signInWithSms:
    "To sign in, use a one-time code from your mobile number along with your password.",
  signInWithEmail: "To sign in, use a one-time code from your email along with your password.",
  signInWithAuthenticator:
    "To sign in, use a one-time code from your authenticator app along with your password. To get started install an authenticator app.",
  step1: "Step 1.",
  step2: "Step 2.",
  textMessage: "Text message",
  textMessageSent: "A text message has been sent to your mobile number ending in {{phoneLast4}}.",
  textMessageNotSent: "An error occurred while sending your text message. Please try again.",
  trustThisDevice: "Trust this device",
  useTextMessage: "Use text message",
  useAuthenticatorApp: "Use an authenticator app",
  useFullscriptEmail: "Use your email linked to your Fullscript account",
  useEmersonEmail: "Use your email linked to your Emerson Ecologics account",
  view: "View",
  createYourAccount: "Create your account",
  swiftfillSignupInstructions: "Please enter your name and password below to create your account.",
  back: "Back",
  platforms: {
    EmersonEcologics: "Emerson Ecologics",
    Fullscript: "Fullscript",
  },
  moreSecure: "More secure",
} as const;
