import type { SerializedStyles } from "@emotion/react";
import type { ChangeEventHandler, InputHTMLAttributes, ReactNode } from "react";
import { PatternFormat } from "react-number-format";

import { FormFieldInformation } from "@aviary/components/FormFieldInformation";

import { useFloatingLabelFocus } from "../useFloatingLabelFocus";

import * as styles from "./DeprecatedFloatingLabelInput.styles";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * Unique field ID
   *
   * @required
   */
  id: string;
  /**
   * Label for the floating text input
   *
   * @required
   */
  label: string;
  /**
   * Allows hiding of the label
   *
   * @default false
   */
  hideLabel?: boolean;
  /**
   * HTML Type of input
   */
  type: "text" | "email" | "password" | "tel" | "number";
  /**
   * Callback from the HTML element on change
   */
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  /**
   * Specifies whether or not phone number value is valid or not
   *
   * @default false
   */
  hasInvalidPhoneNumber?: boolean;
  /**
   * Format for the input number where numbers replace the hashes
   *
   */
  mask?: string;
  /**
   * Values to substitute the mask for before the user has typed
   *
   */
  maskMap?: string[] | string;
  /**
   * Array of field-level errors that is passed into <FormFieldInformation />
   */
  errors?: ReactNode[];
  /**
   * Text shown below the input to describe it's function
   */
  descriptiveText?: ReactNode;
  /**
   * Adds in custom styles to the wrapper div
   */
  wrapperStyles?: SerializedStyles;
  /**
   * Adds in custom styles to the HTML input
   */
  inputStyles?: SerializedStyles | SerializedStyles[];
  /**
   * Specifies whether the input's background is white or default grey
   *
   * @default false
   */
  hasWhiteBackground?: boolean;
  /**
   * adds icon to input field
   *
   * @default false
   */
  icon?: ReactNode;
}

const DeprecatedFloatingLabelInput = ({
  value,
  disabled,
  id,
  label,
  hideLabel,
  type,
  handleChange,
  descriptiveText,
  name,
  onBlur,
  hasInvalidPhoneNumber,
  errors,
  mask,
  wrapperStyles,
  inputStyles,
  hasWhiteBackground,
  icon,
  required,
  placeholder,
  ...rest
}: Props) => {
  const { isFocused, handleFocus, handleBlur, inputElement } = useFloatingLabelFocus(onBlur);

  const hasErrors = (): boolean => {
    if (!errors) return false;

    return errors.filter(e => !!e).length > 0;
  };

  const hasValue = (value !== null && value !== undefined && value !== "") || placeholder;

  const filledStyles = elementStyle => {
    if (hideLabel && type !== "tel") {
      return elementStyle.filled;
    }

    return [elementStyle.filled, elementStyle.filledWithLabel];
  };

  const conditionalFillStyles = elementStyle => {
    return [
      elementStyle.base,
      hasValue && filledStyles(elementStyle),
      isFocused && elementStyle.focused,
      isFocused && filledStyles(elementStyle),
      !isFocused && (hasErrors() || hasInvalidPhoneNumber) && elementStyle.errorStyles,
      disabled && elementStyle.disabled,
      icon && elementStyle.icon,
      hideLabel && elementStyle.hidden,
      hasWhiteBackground && elementStyle.hasWhiteBackground,
      type === "tel" && elementStyle.filled,
    ];
  };

  const conditionalColorStyles = () => {
    return [hideLabel && styles.label.hidden];
  };

  const renderIcon = () => (
    <span css={[styles.icon.base, hideLabel && disabled && styles.icon.disabled]}>{icon}</span>
  );

  const getInputMaskType = (): "text" | "tel" | "password" => {
    if (type === "number" || type === "email") {
      return "text";
    }

    return type;
  };

  const inputDefault = () => {
    return (
      <input
        className="legacy-floatingLabelInput__input"
        css={[conditionalFillStyles(styles.htmlInput), inputStyles]}
        id={id}
        value={value}
        type={type}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        name={name}
        required={required}
        aria-errormessage={`${id}Error`}
        aria-invalid={hasErrors()}
        ref={inputElement}
        placeholder={placeholder}
        {...rest}
      />
    );
  };

  const inputMask = () => {
    const { defaultValue, maskMap, ...numberFormatProps } = rest;

    return (
      <PatternFormat
        format={mask}
        mask={maskMap}
        className="legacy-floatingLabelInput__input"
        css={[conditionalFillStyles(styles.htmlInput), inputStyles]}
        id={id}
        defaultValue={defaultValue as string | number}
        value={value as string | number}
        displayType="input"
        type={getInputMaskType()}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={disabled}
        name={name}
        required={required}
        aria-errormessage={`${id}Error`}
        aria-invalid={hasErrors()}
        inputMode={rest.inputMode}
        placeholder={isFocused ? placeholder : undefined}
        {...numberFormatProps}
      />
    );
  };

  const renderRequiredAsterisk = () => {
    if (required) {
      return (
        <span aria-hidden css={styles.requiredAsterisk}>
          *
        </span>
      );
    }
  };

  const renderInput = () => {
    if (mask) {
      return inputMask();
    }

    return inputDefault();
  };

  return (
    <div
      className="legacy-floatingLabelInput__errorWrapper"
      css={[styles.floatingLabelInputErrorWrapper, wrapperStyles]}
    >
      <div className="legacy-floatingLabelInput__wrapper" css={[styles.floatingLabelWrapper]}>
        <label
          className="legacy-floatingLabelInput__label"
          css={[conditionalFillStyles(styles.label), conditionalColorStyles()]}
          htmlFor={id}
        >
          {label}
          {renderRequiredAsterisk()}
        </label>
        {renderInput()}
        {!!icon && renderIcon()}
      </div>
      <FormFieldInformation
        id={id}
        disabled={disabled}
        data-e2e="form-field-error-information"
        errors={!isFocused && errors}
        descriptiveText={descriptiveText}
      />
    </div>
  );
};

export type { Props as FloatingLabelInputProps };
export { DeprecatedFloatingLabelInput };
