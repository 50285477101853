import type { SyntheticEvent } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Arrange, Link, Modal, ModalContainer, Typography, useToast } from "@aviary";
import { Container } from "@aviary/layouts";
import FsEeDualLogoIcon from "@shared/assets/images/fs-ee-dual-logo-icon.svg";
import mobileImage from "@shared/assets/images/multiplatformNotification/mobile_side_image.jpg";
import image from "@shared/assets/images/multiplatformNotification/side_image.jpg";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useDebounce } from "@shared/hooks/useDebounce/useDebounce";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import {
  type RequestHarmonizedMigrationVerificationEmailData,
  useRequestHarmonizedMigrationVerification,
} from "@unauthenticated/signupSignin/data/mutations/RequestHarmonizedMigrationVerificationEmail.mutation";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./MultiplatformNotificationPage.styles";

interface Props {
  email: string;
}

const MultiplatformNotificationPage = ({ email }: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const { makeToast } = useToast();

  const [requestHarmonizedMigrationVerification] = useRequestHarmonizedMigrationVerification();
  const harmonizedMigrationQuery = () => {
    gRecaptchaExecute(gToken => {
      requestHarmonizedMigrationVerification({
        variables: {
          input: {
            email,
            captchaToken: gToken,
          },
        },
        onCompleted,
      });
    });
  };

  const onCompleted = (data: RequestHarmonizedMigrationVerificationEmailData) => {
    const toastType = data.auth?.requestHarmonizedMigrationVerificationEmail.sent
      ? "success"
      : "error";
    const toastMessage =
      toastType === "success"
        ? l.multiplatformNotification.LinkSent
        : l.multiplatformNotification.LinkError;
    makeToast(toastType, <>{t(toastMessage)}</>);
  };

  const renderSideImage = () => {
    if (tablet.lessThan) {
      return <img src={mobileImage} alt="mobile-side-image" css={styles.mobileImage} />;
    }

    return <img src={image} alt="side-image" css={styles.image} width={280} height={492} />;
  };

  const sendEmail = (e?: SyntheticEvent) => {
    e.preventDefault();
    harmonizedMigrationQuery();
  };

  const handleReSendLink = useDebounce(sendEmail, 500);

  const renderResendLink = () => (
    <Link onClick={handleReSendLink} css={styles.resentLink} data-testid="resend-link">
      {t(l.multiplatformNotification.Resend)}
    </Link>
  );

  return (
    <main tabIndex={0} id="main-content">
      <AuthHeaderWrapper includeBorder includeTopPadding={false}>
        <AuthHeader showFromEmerson />
      </AuthHeaderWrapper>
      <Container>
        <Modal isOpen hideClose css={styles.modal}>
          <div css={[styles.container, tablet.lessThan && styles.mobileContainer]}>
            {renderSideImage()}

            <ModalContainer css={styles.modalContainer}>
              <Arrange isVertical isFullWidth>
                <img
                  css={[styles.dualLogoIcon, tablet.lessThan && styles.mobileLogoIcon]}
                  src={FsEeDualLogoIcon}
                  alt="Fullscript Emerson Ecologics logo"
                />
                <Typography type={tablet.lessThan ? "h4" : "h2"}>
                  <Trans i18nKey={l.multiplatformNotification.Header} />
                </Typography>
                <Typography type={tablet.lessThan ? "h5" : "h4"}>
                  {t(l.multiplatformNotification.Description)}
                </Typography>
                <Typography type="body">
                  <Trans
                    i18nKey={l.multiplatformNotification.EmailedTo}
                    values={{ email }}
                    components={{ bold: <strong /> }}
                  />
                </Typography>
                <Typography type="body">
                  <Trans
                    i18nKey={l.multiplatformNotification.DidNotGetEmail}
                    components={{
                      1: renderResendLink(),
                    }}
                  >
                    {renderResendLink()}
                  </Trans>
                </Typography>
              </Arrange>
            </ModalContainer>
          </div>
        </Modal>
      </Container>
    </main>
  );
};

export { MultiplatformNotificationPage };
