import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const outContainer = css`
  gap: 1rem;
`;

export const messageContainer = css`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.75rem;
  flex: 1 auto;
`;
export const dualIcon = css`
  width: 3rem;
  height: 1.55rem;
`;

export const container = css`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  @media screen and (max-width: ${dimensions.tabletMax}) {
    flex-wrap: wrap;
  }
`;

export const isWrapped = css`
  flex-wrap: wrap;
`;
