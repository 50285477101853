import { Trans, useTranslation } from "react-i18next";

import { Link, Separator, Typography } from "@aviary";
import { BackupCode } from "@shared/components/BackupCode/BackupCode";
import { l } from "@shared/locales/i18n";

import * as styles from "./DisplayBackupCode.styles";

interface Props {
  backupCode: string;
  isChangeFlow?: boolean;
}

const DisplayBackupCode = ({ backupCode, isChangeFlow }: Props) => {
  const { t } = useTranslation();

  const renderTitle = () => {
    if (isChangeFlow) {
      return <Typography type="h4">{t(l.mfa.displayBackupCode.title)}</Typography>;
    }

    return (
      <div css={styles.header}>
        <Typography type="h1">{t(l.mfa.displayBackupCode.title)}</Typography>
      </div>
    );
  };

  return (
    <>
      {renderTitle()}
      <Typography>
        <Trans
          i18nKey={l.mfa.displayBackupCode.enableComplete}
          components={{
            1: (
              <Link href="https://support.fullscript.com/articles/securing-your-practitioner-account-with-multi-factor-verification/#logging-in-with-a-one-time-code" />
            ),
          }}
        />
      </Typography>
      <Separator css={styles.seperator} />
      <Typography isSecondaryWeight isMarginless>
        {t(l.mfa.displayBackupCode.important)}
      </Typography>
      <Typography>{t(l.mfa.displayBackupCode.backupCodeDescription)}</Typography>
      <BackupCode
        backupCode={backupCode}
        copyButtonTextOverride={t(l.mfa.displayBackupCode.copyBackupCode)}
      />
    </>
  );
};

export { DisplayBackupCode };
