import { css, type Theme } from "@emotion/react";
import { borders } from "aviary-tokens";

import { dimensions, layers } from "@styles";
import { hexToRgba } from "@styles/emotion-styles/helpers";

export const modelStyle = {
  small: css`
    & .MuiDialog-paper {
      max-width: 34rem;
      @media (max-width: ${dimensions.phoneLargeMax}) {
        height: auto;
      }
    }
  `,

  medium: css`
    & .MuiDialog-paper {
      max-width: 45rem;
    }
  `,

  large: css`
    & .MuiDialog-paper {
      max-width: 51.75rem;
    }
  `,

  xlarge: css`
    & .MuiDialog-paper {
      max-width: 80rem;
    }
  `,
};
export const content = css`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const modal = (theme: Theme) => css`
  // Material z-index for dialogs is 1300 and it's not enough as our alertbars and navbars are higher.
  // The alternative to not using important here is to define the z-index in the theme provider material has
  // In the  future we should revisit our z-index numbers and make sure that they align correctly if
  // we start using tooltips, drawers, snackbars form material
  // https://material-ui.com/customization/z-index/
  z-index: ${layers.indexModal} !important;
  top: var(--smartbanner-height, 0px);

  & .MuiBackdrop-root {
    background: ${hexToRgba(theme.surface.overlayBackdrop, theme.opacity.overlayBackdrop)};
  }
  & .MuiDialog-paper {
    border-radius: ${borders.radiusModal};
    background-color: ${theme.surface.overlayTheme};
    max-width: 60rem;
    width: 100%;
    box-shadow: ${theme.boxShadows.modalBase};

    @media (max-width: ${dimensions.phoneLargeMax}) {
      border-radius: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin: 0;
      max-height: none;
    }

    @media (min-width: ${dimensions.tablet}) {
      max-height: calc(100dvh - 40px);
      width: 95%;
    }

    @media (min-width: ${dimensions.desktop}) {
      margin: 0;
    }

    @media (max-height: ${dimensions.phoneLarge}) {
      overflow: scroll;
    }
  }
  @media (max-width: ${dimensions.phoneLargeMax}) {
    & .MuiDialog-container {
      overflow-y: scroll;
    }
  }

  //DATE PICKER HACK
  & .MuiDatePickerToolbar-title,
  & .MuiDatePickerToolbar-root span,
  .MuiPickersToolbar-penIconButton,
  .MuiDialogActions-root button {
    color: ${theme.system.textBase};
  }
`;

export const overflowVisible = css`
  & .MuiDialog-paper {
    overflow-y: unset;
    @media (max-height: ${dimensions.phoneLarge}) {
      overflow: unset;
      overflow-y: unset;
    }
  }
`;

export const closeButton = css`
  margin: 0;
  top: 2rem;
  right: 2rem;
`;
