const multiplatformNotification = {
  Header: "Emerson Ecologics and Fullscript:<br />Better Together",
  Description:
    "Now you can use a single account to access healthcare's best supplements on Emerson Ecologics and Fullscript.",
  EmailedTo:
    "You already have an account on both platforms. Click the link in the email we just sent to <bold>{{email}}</bold> to finish setting up your combined account.",
  DidNotGetEmail: "Didn't get the email? Check your spam folder or <1>resend</1>.",
  Resend: "resend",
  LinkSent: "New sign-in link sent",
  LinkError: "Error sending sign-in link",
};

export { multiplatformNotification };
